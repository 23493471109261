import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdvertiseRoutingModule } from './advertise-routing.module';
import { ListComponent } from './list/list.component';
import { FormComponent } from './form/form.component';


@NgModule({
  declarations: [ListComponent, FormComponent],
  imports: [
    CommonModule,
    AdvertiseRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AdvertiseModule { }
