import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, AfterViewInit {

  constructor(
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.get_data();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('app', 'dashboard');
  }
  get_data() {
    var th = this;
    var datas = [];
    setTimeout(() => {
      $('#DataTable').DataTable({
        "bProcessing": true,
        "bServerSide": true,
        "sServerMethod": "POST",
        "sAjaxSource": environment.baseUrl + 'getAdsListNew',
        destroy: true,
        data: datas,
        cache: false,
        order: [[0, 'DESC']],
        columnDefs: [{ "targets": 0, "visible": false }],
        stateSave: true,
        fnDrawCallback: function () {

          $('.btn_edit').click(function () {
            var id = $(this).data('id');
            var url = $(this).data('url');
            th.router.navigate([url + "/" + id]);
          })

          $('.btn_delete').click(function () {
            var id = $(this).data('id');

            /*  */
            Swal.fire({
              title: 'Are you sure delete!',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it'
            }).then((result) => {
              if (result.value) {
                $.ajax({
                  url: environment.baseUrl + 'deleteAds',
                  method: 'POST',
                  data: { id: id },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)
                      th.get_data();
                    } else {
                      th.toastr.error(res.message)
                    }
                  }
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
              }
            })
            /*  */
          })
          $('.change_status').click(function () {
            var id = $(this).data('id');
            var status = $(this).data('status');


            $.ajax({
              url: environment.baseUrl + 'changeAdsStatus',
              method: 'post',
              data: { id: id, status: status },
              dataType: 'json',
              success: function (res) {
                th.DefaultComponent.loaderHide()
                if (res.status) {
                  th.toastr.success(res.message)

                  th.get_data();
                } else {
                  th.toastr.error(res.message)
                }

              }
            });



            // th.router.navigate([url]);
          })

        }
      });
    }, 500);

  }
  get_data_old() {
    var th = this;
    var datas = [];
    this.GeneralService.getAdsList().subscribe((data: any) => {
      if (data.status) {
        setTimeout(function () {


          $.each(data.ads_data, function (index, value) {
            if (value.tam_type == 'home_master_prime_fix') {
              var ads_type = 'Home Master Prime Fix';
            } if (value.tam_type == 'home_master_prime_fix_mobile') {
              var ads_type = 'Home Master Prime Fix Mobile';
            } else if (value.tam_type == 'home_fix_prime_1') {
              var ads_type = 'Home Fix Prime 1';
            } else if (value.tam_type == 'home_fix_prime_2') {
              var ads_type = 'Home Fix Prime 2';
            } else if (value.tam_type == 'home_fix_prime_3') {
              var ads_type = 'Home Fix Prime 3';
            } else if (value.tam_type == 'home_fix_prime_4') {
              var ads_type = 'Home Fix Prime 4';
            } else if (value.tam_type == 'home_fix_prime_5') {
              var ads_type = 'Home Fix Prime 5';
            } else if (value.tam_type == 'home_fix_prime_6') {
              var ads_type = 'Home Fix Prime 6';
            } else if (value.tam_type == 'home_fix_prime_7') {
              var ads_type = 'Home Fix Prime 7';
            } else if (value.tam_type == 'home_fix_prime_8') {
              var ads_type = 'Home Fix Prime 8';
            } else if (value.tam_type == 'home_box_slider_banner') {
              var ads_type = 'Home Box Slider Banner';
            } else if (value.tam_type == 'home_top_banner') {
              var ads_type = 'Home Top Banner';
            } else if (value.tam_type == 'home_strip_banner') {
              var ads_type = 'Home Strip Banner';
            } else if (value.tam_type == 'home_business_card') {
              var ads_type = 'Home Business Card';
            } else if (value.tam_type == 'cat_prime_fix') {
              var ads_type = 'Category Prime Fix';
            } else if (value.tam_type == 'cat_top_banner') {
              var ads_type = 'Category Top Banner';
            } else if (value.tam_type == 'cat_slider') {
              var ads_type = 'Category Slider';
            } else if (value.tam_type == 'cat_business_card') {
              var ads_type = 'Category Business Card';
            }

            var status = (value.tam_status) ? '<a href="javascript:;" data-id="' + value.tam_id + '" data-status="0" class="badge bg-success change_status">Active</a>' : '<a href="javascript:;" data-id="' + value.tam_id + '" data-status="1" class="badge bg-danger change_status">Deactive</a>';
            datas.push([
              value.tam_id,
              value.tm_company_name,
              ads_type,
              status,
              '<button  data-url="/advertise-edit/' + value.tam_id + '" class="edit_btn btn btn-primary btn-icon mr-2"><i class="fa fa-edit"></i></button>&nbsp;' +
              '<button type="button" data-id=' + value.tam_id + ' class="delete_btn btn btn-danger btn-icon"><i class="fa fa-trash"></i></button>'
            ]);
          });

          $('#DataTable').DataTable({
            destroy: true,
            data: datas,
            cache: false,
            order: [[0, 'DESC']],
            columnDefs: [{ "targets": 0, "visible": false }],
            stateSave: true,
            fnDrawCallback: function () {
              $('.edit_btn').click(function () {
                var url = $(this).data('url');
                th.router.navigate([url]);
              })
              $('.delete_btn').click(function () {
                var id = $(this).data('id');
                th.remove(id);
              })
              $('.change_status').click(function () {
                var id = $(this).data('id');
                var status = $(this).data('status');
                th.DefaultComponent.loaderShow()
                $.ajax({
                  url: environment.baseUrl + 'changeAdsStatus',
                  method: 'post',
                  data: { id: id, status: status },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)

                      th.get_data();
                    } else {
                      th.toastr.error(res.message)
                    }

                  }
                });
              });
            }
          });
        }, 500);
      }
    });
  }
  remove(id) {
    var th = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'to Delete this record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        th.DefaultComponent.loaderShow()
        $.ajax({
          url: environment.baseUrl + 'deleteAds',
          method: 'POST',
          data: { id: id },
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide()
            if (res.status) {
              th.toastr.success(res.message)
              th.get_data();
            } else {
              th.toastr.error(res.message)
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('cancel')
      }
    })

  }
}
