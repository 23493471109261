import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
declare var $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {
  public Editor = ClassicEditor;
  public memberForm: FormGroup;
  public isSubmit = false;
  categoryIcon: any = '';
  memberBanner: any = '';
  memberBrochure: any = '';
  memberLogo: any = '';
  memberOriginalLogo: any = '';
  member_id: any = 0;
  categoryList: any = [];
  product_list: any[] = [{
    title: '',
    image: '',
    thumb: ''
  }];
  search_tag_list: any[] = [{
    value: ''
  }];
  constructor(
    private route: ActivatedRoute,
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private fb: FormBuilder,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.route.params.subscribe(params => this.member_id = params.id);
    this.GeneralService.getCategoryListActive().subscribe((data: any) => {
      if (data['status']) {
        this.categoryList = data.category_data;
      }
    });

    this.memberForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      categories: [''],
      ofc_address: [''],
      work_address: ['', [Validators.required, Validators.minLength(10)]],
      phone_1: [''],
      phone_2: [''],
      email_1: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      email_2: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      member_name_1: [''],
      website: ['', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      member_mobile_1: [''],
      member_name_2: [''],
      member_mobile_2: [''],
      business_type: [''],
      brand_name: [''],
      certification: [''],
      about_company: [''],
      facebook_link: [''],
      twitter_link: [''],
      youtube_link: [''],
      instagram_link: [''],
      pinterest_link: [''],
      linkedin_link: [''],
      map_iframe: [''],
      status: [false],
      verified: [false],
      is_member: [false],
      lifetime_member: [false],
      paid_member: [false],
      id: ['0'],
      logo: [''],
      original_logo: [''],
      banner: [''],
      brochure: ['']
    });



  }
  update_product_title(e, index) {
    this.product_list[index]['title'] = e.target.value;
  }
  update_search_tag(e, index) {
    this.search_tag_list[index]['value'] = e.target.value;
  }
  addProductValue() {
    this.product_list.push({
      title: '',
      image: '',
      thumb: '',
    });
  }
  removeProductValue(i: number) {
    this.product_list.splice(i, 1);
  }
  addSearchTagValue() {
    this.search_tag_list.push({
      value: ''
    });
  }
  removeSearchTagValue(i: number) {
    this.search_tag_list.splice(i, 1);
  }
  setProductDetail() {
    if (this.member_id) {
      var param = { id: this.member_id };
      this.GeneralService.getMemberDetail(param).subscribe((data: any) => {
        if (data['status']) {
          if (data.member_data.tm_company_logo) {
            this.memberLogo = environment.baseUrl + "member/logo/thumb/" + data.member_data.tm_company_logo;
          }
          if (data.member_data.tm_original_logo) {
            this.memberOriginalLogo = environment.baseUrl + "member/logo/thumb/" + data.member_data.tm_original_logo;
          }
          if (data.member_data.tm_company_banner) {
            this.memberBanner = environment.baseUrl + "member/banner/thumb/" + data.member_data.tm_company_banner;
          }
          if (data.member_data.tm_company_brochure) {
            this.memberBrochure = environment.baseUrl + "member/brochure/" + data.member_data.tm_company_brochure;
          }


          this.memberForm.patchValue({
            name: data.member_data.tm_company_name,
            ofc_address: data.member_data.tm_office_address,
            work_address: data.member_data.tm_work_address,
            phone_1: data.member_data.tm_phone_1,
            phone_2: data.member_data.tm_phone_2,
            email_1: data.member_data.tm_email_1,
            email_2: data.member_data.tm_email_2,
            member_name_1: data.member_data.tm_member_name_1,
            member_mobile_1: data.member_data.tm_member_mobile_1,
            member_name_2: data.member_data.tm_member_name_2,
            member_mobile_2: data.member_data.tm_member_mobile_2,
            business_type: data.member_data.tm_business_type,
            brand_name: data.member_data.tm_brand_name,
            certification: data.member_data.tm_certification,
            verified: data.member_data.tm_is_verified,
            is_member: data.member_data.tm_is_member,
            lifetime_member: data.member_data.tm_is_life_time_gold_member,
            paid_member: data.member_data.tm_is_paid_member,
            status: data.member_data.tm_status,
            about_company: data.member_data.tm_about_us,
            facebook_link: data.member_data.tm_fb_link,
            twitter_link: data.member_data.tm_twitter_link,
            youtube_link: data.member_data.tm_youtube_link,
            instagram_link: data.member_data.tm_instagram_link,
            pinterest_link: data.member_data.tm_pinterest_link,
            linkedin_link: data.member_data.tm_linkedin_link,
            map_iframe: data.member_data.tm_map_iframe,
            website: data.member_data.tm_website,
            id: data.member_data.tm_id,
          });
          if (data.member_data.tm_company_products && data.member_data.tm_company_products.length > 0) {
            this.product_list = [];
            for (var i = 0; i < data.member_data.tm_company_products.length; i++) {
              var image = '';
              if (data.member_data.tm_company_products[i]['image']) {
                image = environment.baseUrl + "member/product/" + data.member_data.tm_company_products[i]['image']
              } else {
                image = '';
              }
              this.product_list.push({
                title: data.member_data.tm_company_products[i]['title'],
                image: '',
                thumb: image,
              });
            }
          }
          if (data.search_data) {
            this.search_tag_list = [];
            for (var i = 0; i < data.search_data.length; i++) {
              this.search_tag_list.push({
                value: data.search_data[i]['tmst_tag']
              });
            }
          }
          setTimeout(() => {
            $('#categories').val(data.member_data.tm_categories);
            $('#categories').trigger('change');
          }, 500);


        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    }
  }
  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {
      var categories = $("#categories").val();
      //var categories_text = $('#categories').find(':selected').text();
      var categories_text = $('#categories').select2('data');

      if (categories.length > 0) {
        this.DefaultComponent.loaderShow();
        var category_data = [];
        for (var i = 0; i < categories_text.length; i++) {
          category_data.push({
            id: categories_text[i]['id'],
            text: categories_text[i]['text']
          })
        }
        var formData = new FormData();
        formData.append('name', formDetail.name);
        formData.append('categories', categories);
        formData.append('categories_text', JSON.stringify(category_data));
        formData.append('ofc_address', formDetail.ofc_address);
        formData.append('work_address', formDetail.work_address);
        formData.append('phone_1', formDetail.phone_1);
        formData.append('phone_2', formDetail.phone_2);
        formData.append('email_1', formDetail.email_1);
        formData.append('email_2', formDetail.email_2);
        formData.append('member_name_1', formDetail.member_name_1);
        formData.append('member_mobile_1', formDetail.member_mobile_1);
        formData.append('member_name_2', formDetail.member_name_2);
        formData.append('member_mobile_2', formDetail.member_mobile_2);
        formData.append('business_type', formDetail.business_type);
        formData.append('brand_name', formDetail.brand_name);
        formData.append('certification', formDetail.certification);
        formData.append('status', formDetail.status);
        formData.append('verified', formDetail.verified);
        formData.append('is_member', formDetail.is_member);
        formData.append('lifetime_member', formDetail.lifetime_member);
        formData.append('paid_member', formDetail.paid_member);
        formData.append('id', formDetail.id);
        formData.append('logo', formDetail.logo);
        formData.append('original_logo', formDetail.original_logo);
        formData.append('banner', formDetail.banner);
        formData.append('brochure', formDetail.brochure);
        formData.append('website', formDetail.website);
        formData.append('about_company', formDetail.about_company);
        formData.append('facebook_link', formDetail.facebook_link);
        formData.append('twitter_link', formDetail.twitter_link);
        formData.append('youtube_link', formDetail.youtube_link);
        formData.append('instagram_link', formDetail.instagram_link);
        formData.append('pinterest_link', formDetail.pinterest_link);
        formData.append('linkedin_link', formDetail.linkedin_link);
        formData.append('map_iframe', formDetail.map_iframe);
        formData.append('login_id', this.DefaultComponent.login_id);
        for (var i = 0; i < this.product_list.length; i++) {
          if (this.product_list[i].image) {
            formData.append('products_image' + i, this.product_list[i].image);
            this.product_list[i]['thumb'] = '';
          }
        }
        formData.append('products', JSON.stringify(this.product_list));
        formData.append('search_tag', JSON.stringify(this.search_tag_list));
        this.GeneralService.saveMember(formData).subscribe((res: any) => {
          this.DefaultComponent.loaderHide()
          var data = res;
          if (data.status) {
            if (formDetail.id > 0) {
              this.toastr.success(data.message)
              this.router.navigate(['/member-list']);
            } else {
              this.toastr.success(data.message)
              location.reload();
            }
          } else {
            this.toastr.error(data.message)
          }
        }, (err: any) => {
          console.error('Error:-', err);
        });
      } else {
        this.toastr.error("Please select category")
      }

    }
  }
  onFileChangeProduct(event, index) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#image" + index).val('');
        this.toastr.error("Only images are supported for product");
        return;
      }
      this.product_list[index]['image'] = file;
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.product_list[index]['thumb'] = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  onFileChangeBrochure(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/pdf\/*/) == null) {
        $("#brochure").val('');
        this.toastr.error("Only pdf are supported in brochure");
        return;
      }
      this.memberForm.patchValue({
        brochure: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {

      }
      reader.readAsDataURL(file)
    }
  }
  onFileChangeBanner(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#banner").val('');
        this.toastr.error("Only images are supported for banner");
        return;
      }
      this.memberForm.patchValue({
        banner: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.memberBanner = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#logo").val('');
        this.toastr.error("Only images are supported for logo");
        return;
      }
      this.memberForm.patchValue({
        logo: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.memberLogo = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  onFileChange2(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#original_logo").val('');
        this.toastr.error("Only images are supported for logo");
        return;
      }
      this.memberForm.patchValue({
        original_logo: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.memberOriginalLogo = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');
    $(".select2").select2();
    this.setProductDetail();

  }
}