import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService, SPINNER } from "ngx-ui-loader";
declare var $: any;
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})
export class FullComponent implements OnInit {
  SPINNER: SPINNER;
  spinner_type = SPINNER.threeStrings;
  fgsColor = '#f58532';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
  ) {
    if (localStorage.getItem('svia_admin_data')) {
      this.router.navigate(['/dashboard']);
    }
  }
  loaderShow() {
    this.ngxService.start();
  }
  loaderHide() {
    this.ngxService.stop();
  }
  ngOnInit(): void {
    $("body").addClass('auth-body-bg');
  }

}
