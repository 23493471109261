import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { FullComponent } from '../../layouts/full/full.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public signInForm: FormGroup;
  public isSubmit = false;
  constructor(private fb: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private toastr: ToastrService,
    private FullComponent: FullComponent) {

    this.signInForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],

    });
  }

  ngOnInit(): void {
  }
  signIn(event: Event, isValid: Boolean, formDetail: any) {

    if (isValid) {
      this.FullComponent.loaderShow();
      var parameter = { "username": formDetail.username, "password": formDetail.password }
      this.AuthService.signIn(parameter).subscribe((res: any) => {
        this.FullComponent.loaderHide()
        var data = res;
        if (data.status) {
          var user_data = data.admin_data

          localStorage.setItem('svia_login_id', user_data.ta_id);
          localStorage.setItem('svia_login_name', user_data.ta_username);
          localStorage.setItem('svia_admin_data', JSON.stringify(user_data));

          this.router.navigate(['/dashboard']);
        } else {
          this.toastr.error(data.message)
        }

      }, (err: any) => {
        console.error('Error:-', err);
      });



    }
  }
}
