<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Manage Categories</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a routerLink="/category-list">Categories</a></li>
                            <li class="breadcrumb-item active">Add New</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-6">
                <div class="card">
                    <div class="card-body">

                        <h4 class="card-title">Add New Category</h4>
                        <p class="card-title-desc"></p>
                        <form class="" [formGroup]="categoryForm"
                            (ngSubmit)="isSubmit= true; submitForm($event, categoryForm.valid, categoryForm.value)">
                            <div class="row mb-3">
                                <label for="example-text-input" class="col-sm-2 col-form-label">Name</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" formControlName="name" placeholder="Name"
                                        id="example-text-input">
                                    <span *ngIf="categoryForm.get('name')?.errors && isSubmit">
                                        <span *ngIf="categoryForm.get('name')?.errors?.required && isSubmit"
                                            class="text-danger">
                                            Please enter category name
                                        </span>
                                        <span
                                            *ngIf="categoryForm.get('name')?.errors?.maxlength || categoryForm.get('name')?.errors?.minlength "
                                            class="text-danger">
                                            Category name should be of minimum 3 characters OR a maximum of 30
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="example-search-input" class="col-sm-2 col-form-label">Icon</label>
                                <div class="col-sm-10">

                                    <input type="file" class="form-control" id="customFile"
                                        (change)="onFileChange($event)">
                                    <span *ngIf="categoryForm.get('fileSource')?.errors && isSubmit">
                                        <span *ngIf="categoryForm.get('fileSource')?.errors?.required && isSubmit"
                                            class="text-danger">
                                            Category icon must be require
                                        </span>

                                    </span>
                                    <img [src]="categoryIcon" *ngIf="categoryIcon" alt="category-icon"
                                        class="rounded avatar-sm mt-2">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="example-email-input" class="col-sm-2 col-form-label">Status</label>
                                <div class="col-sm-10">
                                    <div class="form-check form-switch mb-3" dir="ltr">
                                        <input type="checkbox" formControlName="status" class="form-check-input"
                                            id="customSwitch1">
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="example-text-input" class="col-sm-2 col-form-label">Meta Title</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" formControlName="metaTitle"
                                        placeholder="Meta Title" id="example-text-input">

                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="example-text-input" class="col-sm-2 col-form-label">Meta Description</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" formControlName="metaDescription"
                                        placeholder="Meta Description" id="example-text-input">

                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="example-text-input" class="col-sm-2 col-form-label">Meta Keyword</label>
                                <div class="col-sm-10">
                                    <input class="form-control" type="text" formControlName="metaKeyword"
                                        placeholder="Meta Keyword" id="example-text-input">

                                </div>
                            </div>
                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div> <!-- end col -->
        </div>
        <!-- end row -->


        <!-- end row -->
    </div> <!-- container-fluid -->
</div>