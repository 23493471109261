import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  public Editor = ClassicEditor;
  public memberExcelForm: FormGroup;
  public isSubmit = false;
  excel: any = '';
  categoryList: any = [];
  inputFile: any = 0;
  member_id: any = 0;
  isExcelFile: boolean = false;
  excelData: any = [];

  constructor(
    private route: ActivatedRoute,
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private fb: FormBuilder,
    private http: HttpClient,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.route.params.subscribe(params => this.member_id = b64_to_utf8(params.id));
    this.GeneralService.getCategoryListActive().subscribe((data: any) => {
      if (data['status']) {
        this.categoryList = data.category_data;
      }
    });

    function b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
    }

    this.memberExcelForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      work_address: ['', [Validators.required, Validators.minLength(10)]],
      ofc_address: [''],
      Contact_Person: [''],
      Contact_No: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      id: ['0'],
      Village: [''],
      Email_ID_2: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      Email_ID: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      Contact_No_2: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Contact_Person_2: [''],
      Brand: [''],
      business_type: [''],
      Contact_Person_No: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Contact_Person_No_2: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Certified: [''],
      website: [''],
    });
  }

  ngOnInit(): void {
    var param = { id: this.member_id };
    this.GeneralService.getMemberExcelDetail(param).subscribe((data: any) => {
      if (data['status']) {
        if (data.member_data.update_status == 1) {
          // $("#memberExcelFormHideShow").html('')
        }
      }
    })
  }

  showExcelPreview(evt) {
    this.excelData = [];
    let data, header;
    const target: DataTransfer = <DataTransfer>(evt.target);
    //get target.files extension

    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx|.csv)/);

    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      var isCSVfile = !!target.files[0].name.match(/(.csv)/);

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;

        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        //get a1 value

        if (isCSVfile) {
          var checkHeader = ws.A5.v == 'No.' && ws.B5.v == 'Dated' && ws.C5.v == 'Name' && ws.D5.v == 'Address' && ws.E5.v == 'Village' && ws.F5.v == 'Contact Person' && ws.G5.v == 'Contact No.' && ws.H5.v == 'Contact Person' && ws.I5.v == 'Contact No.' && ws.J5.v == 'Email ID' && ws.K5.v == 'Email ID2' && ws.L5.v == 'text' && ws.M5.v == 'Type';

        } else {
          var checkHeader = ws.A5.h == 'No.' && ws.B5.h == 'Dated' && ws.C5.h == 'Name' && ws.D5.h == 'Address' && ws.E5.h == 'Village' && ws.F5.h == 'Contact Person' && ws.G5.h == 'Contact No.' && ws.H5.h == 'Contact Person' && ws.I5.h == 'Contact No.' && ws.J5.h == 'Email ID' && ws.K5.h == 'Email ID2' && ws.L5.h == 'text' && ws.M5.h == 'Type';
        }

        if (checkHeader) {
          data = XLSX.utils.sheet_to_json(ws);
          if (data && data.length > 0) {
            //get all key from data
            this.excelData = data;
            console.log(this.excelData);
          } else {
            this.toastr.error("No data found in excel/csv.");
            $("#excel").val("");
          }
        } else {
          this.toastr.error("Please upload correct excel / csv file.");
          $("#excel").val("");
          return false;
        }
      };
      reader.readAsBinaryString(target.files[0]);

    } else {
      this.toastr.error("You can upload excel / csv file.");
      $("#excel").val('');
      // $("#btnSubmit").attr("disabled", true);
      this.inputFile.nativeElement.value = '';
    }
  }

  convertInt(input) {
    // Replace all spaces with an empty string
    var processed = input.replace(/ /g, '');
    var output = parseInt(processed, 10);
    return output;
  }
  replaceText(text) {
    var data = '';
    if (text == 'null') {
    } else {
      data = text;
    }
    return data;
  }

  setProductDetail() {
    if (this.member_id) {
      var param = { id: this.member_id };
      this.GeneralService.getMemberExcelDetail(param).subscribe((data: any) => {
        console.log(data.member_data);
        if (data['status']) {
          this.memberExcelForm.patchValue({
            name: data.member_data.name,
            work_address: data.member_data.address,
            ofc_address: this.replaceText(data.member_data.office_address),
            Village: data.member_data.village,
            Contact_No: data.member_data.contact_no,
            Contact_Person: data.member_data.contact_person,
            Email_ID_2: data.member_data.email_id_2,
            Email_ID: data.member_data.email_id,
            Contact_No_2: data.member_data.contact_no_2,
            Contact_Person_2: data.member_data.contact_person_2,
            Brand: data.member_data.text,
            business_type: data.member_data.type,
            Contact_Person_No: this.replaceText(data.member_data.contact_person_no),
            Contact_Person_No_2: this.replaceText(data.member_data.contact_person_no_2),
            Certified: this.replaceText(data.member_data.certified),
            website: data.member_data.website,
            id: data.member_data.tmd_id,
          })
          setTimeout(() => {
            $('#categories').val(data.member_data.category);
            $('#categories').trigger('change');
          }, 500);
        }
      })
    }
  }

  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {
      var categories = $("#categories").val();
      var categories_text = $('#categories').select2('data');
      if (categories.length > 0) {
        // this.DefaultComponent.loaderShow();
        var category_data = [];
        for (var i = 0; i < categories_text.length; i++) {
          category_data.push({
            id: categories_text[i]['id'],
            text: categories_text[i]['text']
          })
        }
      } else {
        var category_data = [];
        categories = [];
      }
      // var parameter = formDetail;
      var formData = new FormData();
      formData.append('id', formDetail.id);
      formData.append('name', formDetail.name);
      formData.append('Address', formDetail.work_address);
      formData.append('categories', categories);
      formData.append('Categories_Text', JSON.stringify(category_data));
      formData.append('ofc_address', formDetail.ofc_address);
      formData.append('Village', formDetail.Village);
      formData.append('Contact_No', formDetail.Contact_No);
      formData.append('Contact_Person', formDetail.Contact_Person);
      formData.append('Email_ID_2', formDetail.Email_ID_2);
      formData.append('Email_ID', formDetail.Email_ID);
      formData.append('Contact_No_2', formDetail.Contact_No_2);
      formData.append('Contact_Person_2', formDetail.Contact_Person_2);
      formData.append('Brand', formDetail.Brand);
      formData.append('business_type', formDetail.business_type);
      formData.append('Contact_Person_No', formDetail.Contact_Person_No);
      formData.append('Contact_Person_No_2', formDetail.Contact_Person_No_2);
      formData.append('Certified', formDetail.Certified);
      formData.append('website', formDetail.website);
      this.GeneralService.saveMemberExcelDetails(formData).subscribe((res: any) => {
        this.DefaultComponent.loaderHide()
        var data = res;
        if (data.status) {
          this.toastr.success(data.message)
          this.router.navigate(['/excel-list']);
        } else {
          this.toastr.error(data.message)
        }
      })

    }
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');
    $(".select2").select2();
    this.setProductDetail();
  }
}