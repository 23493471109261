import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from '../layouts/full/full.component';
import { LoginComponent } from '../auth/login/login.component';
const routes: Routes = [{
  path: '',
  component: FullComponent,
  children: [{
    path: '',
    component: LoginComponent
  }, {
    path: 'signin',
    component: LoginComponent
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }