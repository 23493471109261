import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form/form.component';
import { ExcelListComponent } from './excel-list/excel-list.component';
import { DefaultComponent } from '../layouts/default/default.component';
import { FullComponent } from '../layouts/full/full.component';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{
    path: 'member-excel-add/:id',
    component: FormComponent
  }, {
    path: 'excel-list',
    component: ExcelListComponent
  }, {
    path: 'add-member-excel',
    component: FormComponent
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberexcelRoutingModule { }