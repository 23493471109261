import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  serverUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  /*---------category------------*/
  public saveCategory(payload) {
    return this.http.post(this.serverUrl + 'saveCategory', payload);
  }
  public saveMemberExcel(payload) {
    return this.http.post(this.serverUrl + 'saveMemberExcel', payload);
  }
  public getCategoryList() {
    return this.http.post(this.serverUrl + 'getCategoryList', {});
  }
  public getCategoryListActive() {
    return this.http.post(this.serverUrl + 'getCategoryListActive', {});
  }
  public getMemberExcelList() {
    return this.http.post(this.serverUrl + 'getMemberExcelList', {});
  }
  public getCategoryDetail(param) {
    return this.http.post(this.serverUrl + 'getCategoryDetail', param);
  }
  /*---------member------------*/
  public saveMember(payload) {
    return this.http.post(this.serverUrl + 'saveMember', payload);
  }

  public saveMemberExcelDetails(payload) {
    return this.http.post(this.serverUrl + 'saveMemberExcelDetails', payload);
  }
  public getMemberList() {
    return this.http.post(this.serverUrl + 'getMemberListNew', {});
  }
  public getMemberDetail(param) {
    return this.http.post(this.serverUrl + 'getMemberDetail', param);
  }
  public getMemberExcelDetail(param) {
    return this.http.post(this.serverUrl + 'getMemberExcelDetail', param);
  }
  public getMemberListActive() {
    return this.http.post(this.serverUrl + 'getMemberListActive', {});
  }

  public getMemberAdvertiseList(param) {
    return this.http.post(this.serverUrl + 'getMemberAdvertiseList', param);
  }
  public saveAds(payload) {
    return this.http.post(this.serverUrl + 'saveAds', payload);
  }
  public getAdsList() {
    return this.http.post(this.serverUrl + 'getAdsList', {});
  }

  public getAdsDetail(param) {
    return this.http.post(this.serverUrl + 'getAdsDetail', param);
  }
  public getDashboardData() {
    return this.http.post(this.serverUrl + 'getDashboardData', {});
  }
}