<div>
    <div class="container-fluid p-0">
        <div class="row g-0">
            <div class="col-lg-4">
                <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div class="w-100">
                        <div class="row justify-content-center">
                            <div class="col-lg-9">
                                <div>
                                    <div class="text-center">
                                        <div>
                                            <a href="" class="">
                                                <img src="assets/images/logo.png" alt="" height="20"
                                                    class="auth-logo logo-dark mx-auto">
                                                <img src="assets/images/logo.png" alt="" height="20"
                                                    class="auth-logo logo-light mx-auto">
                                            </a>
                                        </div>

                                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                                        <p class="text-muted">Sign in to continue to SVIA Admin.</p>
                                    </div>

                                    <div class="p-2 mt-5">
                                        <form class="" [formGroup]="signInForm"
                                            (ngSubmit)="isSubmit= true; signIn($event, signInForm.valid, signInForm.value)">

                                            <div class="mb-3 auth-form-group-custom mb-4">
                                                <i class="ri-user-2-line auti-custom-input-icon"></i>
                                                <label for="username">Username</label>
                                                <input type="text" class="form-control" formControlName="username"
                                                    id="username" name="username" placeholder="Enter username">
                                                <span *ngIf="signInForm.get('username')?.errors && isSubmit">
                                                    <span
                                                        *ngIf="signInForm.get('username')?.errors?.required && isSubmit"
                                                        class="text-danger">
                                                        Please enter username
                                                    </span>
                                                </span>
                                            </div>

                                            <div class="mb-3 auth-form-group-custom mb-4">
                                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                <label for="userpassword">Password</label>
                                                <input type="password" class="form-control" formControlName="password"
                                                    id="password" name="password" placeholder="Enter password">
                                                <span *ngIf="signInForm.get('password')?.errors && isSubmit">
                                                    <span
                                                        *ngIf="signInForm.get('password')?.errors?.required && isSubmit"
                                                        class="text-danger">
                                                        Please enter password
                                                    </span>
                                                </span>
                                            </div>



                                            <div class="mt-4 text-center">
                                                <button class="btn btn-primary w-md waves-effect waves-light"
                                                    type="submit">Log In</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="mt-5 text-center">
                                        <p>©
                                            2021 SVIA Rajkot. Crafted
                                            with <i class="mdi mdi-heart text-danger"></i> by <a
                                                href="https://www.gteches.com" target="_blank">Gtech</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="authentication-bg">
                    <div class="bg-overlay"></div>
                </div>
            </div>
        </div>
    </div>
</div>