import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from '../layouts/full/full.component';
import { FormComponent } from '../link/form/form.component'

const routes: Routes = [{
  path: '',
  component: FullComponent,
  children: [{
    path: 'links/:id',
    component: FormComponent
  }],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LinkRoutingModule { }