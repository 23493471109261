import { FullComponent } from '../../layouts/full/full.component';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as XLSX from 'xlsx';
declare var $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  public Editor = ClassicEditor;
  public memberExcelDetailsForm: FormGroup;
  public isSubmit = false;
  excel: any = '';
  categoryList: any = [];
  inputFile: any = 0;
  member_id: any = 0;
  isExcelFile: boolean = false;
  excelData: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private FullComponent: FullComponent,
    private GeneralService: GeneralService,
    private route: ActivatedRoute,
    private scriptService: ScriptService,

  ) {
    this.route.params.subscribe(params => this.member_id = b64_to_utf8(params.id));
    this.GeneralService.getCategoryListActive().subscribe((data: any) => {
      if (data['status']) {
        this.categoryList = data.category_data;
      }
    });
    function b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
    }
    this.memberExcelDetailsForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      work_address: ['', [Validators.required, Validators.minLength(10)]],
      ofc_address: [''],
      Contact_Person: [''],
      Contact_No: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      id: ['0'],
      Village: [''],
      Email_ID_2: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      Email_ID: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$')]],
      Contact_No_2: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Contact_Person_2: [''],
      Brand: [''],
      business_type: [''],
      Contact_Person_No: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Contact_Person_No_2: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Certified: [''],
    });
  }

  ngOnInit(): void {
    var param = { id: this.member_id };
    this.GeneralService.getMemberExcelDetail(param).subscribe((data: any) => {
      if (data['status']) {
        if (data.member_data.update_status == 1) {
          $("#memberExcelFormHideShow").html('')
        }
      }
    })
  }
  convertInt(input) {
    // Replace all spaces with an empty string
    var processed = input.replace(/ /g, '');
    var output = parseInt(processed, 10);
    return output;
  }

  replaceText(text) {
    var data = '';
    if (text == 'null') {
    } else {
      data = text;
    }
    return data;

  }

  setProductDetail() {
    if (this.member_id) {
      var param = { id: this.member_id };
      this.GeneralService.getMemberExcelDetail(param).subscribe((data: any) => {
        console.log(data.member_data);
        if (data['status']) {
          this.memberExcelDetailsForm.patchValue({
            name: data.member_data.name,
            work_address: data.member_data.address,
            ofc_address: this.replaceText(data.member_data.office_address),
            Village: data.member_data.village,
            Contact_No: this.convertInt(data.member_data.contact_no),
            Contact_Person: data.member_data.contact_person,
            Email_ID_2: data.member_data.email_id_2,
            Email_ID: data.member_data.email_id,
            Contact_No_2: this.convertInt(data.member_data.contact_no_2),
            Contact_Person_2: data.member_data.contact_person_2,
            Brand: data.member_data.text,
            business_type: data.member_data.type,
            Contact_Person_No: this.replaceText(data.member_data.contact_person_no),
            Contact_Person_No_2: this.replaceText(data.member_data.contact_person_no_2),
            Certified: this.replaceText(data.member_data.certified),
            id: data.member_data.tmd_id,
          })
          setTimeout(() => {
            $('#categories').val(data.member_data.category);
            $('#categories').trigger('change');
          }, 500);
        }
      })
    }
  }
  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {
      var categories = $("#categories").val();
      var categories_text = $('#categories').select2('data');
      if (categories.length > 0) {
        // this.DefaultComponent.loaderShow();
        var category_data = [];
        for (var i = 0; i < categories_text.length; i++) {
          category_data.push({
            id: categories_text[i]['id'],
            text: categories_text[i]['text']
          })
        }
      } else {
        var category_data = [];
        categories = [];
      }
      // var parameter = formDetail;
      var formData = new FormData();
      formData.append('id', formDetail.id);
      formData.append('name', formDetail.name);
      formData.append('Address', formDetail.work_address);
      formData.append('categories', categories);
      formData.append('Categories_Text', JSON.stringify(category_data));
      formData.append('ofc_address', formDetail.ofc_address);
      formData.append('Village', formDetail.Village);
      formData.append('Contact_No', formDetail.Contact_No);
      formData.append('Contact_Person', formDetail.Contact_Person);
      formData.append('Email_ID_2', formDetail.Email_ID_2);
      formData.append('Email_ID', formDetail.Email_ID);
      formData.append('Contact_No_2', formDetail.Contact_No_2);
      formData.append('Contact_Person_2', formDetail.Contact_Person_2);
      formData.append('Brand', formDetail.Brand);
      formData.append('business_type', formDetail.business_type);
      formData.append('Contact_Person_No', formDetail.Contact_Person_No);
      formData.append('Contact_Person_No_2', formDetail.Contact_Person_No_2);
      formData.append('Certified', formDetail.Certified);
      this.GeneralService.saveMemberExcelDetails(formData).subscribe((res: any) => {
        this.FullComponent.loaderHide()
        var data = res;
        if (data.status) {
          this.toastr.success(data.message)
        } else {
          this.toastr.error(data.message)
        }
      })

    }
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');
    $(".select2").select2();
    this.setProductDetail();
  }
}