import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
declare function sendDataToAndroid(input: string): void;
declare var $: any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, AfterViewInit {

  constructor(
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.get_data();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('app', 'dashboard');
  }
  get_data() {
    var th = this;
    var datas = [];
    setTimeout(() => {
      $('#DataTable').DataTable({
        "bProcessing": true,
        "bServerSide": true,
        "sServerMethod": "POST",
        "sAjaxSource": environment.baseUrl + 'getMemberListNew',
        destroy: true,
        data: datas,
        cache: false,
        order: [[0, 'DESC']],
        columnDefs: [{ "targets": 0, "visible": false }],
        stateSave: true,
        fnDrawCallback: function () {

          $('.btn_edit').click(function () {
            var id = $(this).data('id');
            var url = $(this).data('url');
            th.router.navigate([url + "/" + id]);
          })
          $('.verify_btn').click(function () {
            var id = $(this).data('id');
            th.send_data_to_android(id);
          })
          $('.btn_delete').click(function () {
            var id = $(this).data('id');

            /*  */
            Swal.fire({
              title: 'Are you sure delete!',
              text: '',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, keep it'
            }).then((result) => {
              if (result.value) {
                $.ajax({
                  url: environment.baseUrl + 'deleteMember',
                  method: 'POST',
                  data: { id: id },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)
                      th.get_data();
                    } else {
                      th.toastr.error(res.message)
                    }
                  }
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // console.log('cancel')
              }
            })
            /*  */
          })
          $('.change_status').click(function () {
            var id = $(this).data('id');
            var status = $(this).data('status');
            console.log(id);
            console.log(status);

            $.ajax({
              url: environment.baseUrl + 'changeMemberStatus',
              method: 'post',
              data: { id: id, status: status },
              dataType: 'json',
              success: function (res) {
                th.DefaultComponent.loaderHide()
                if (res.status) {
                  th.toastr.success(res.message)

                  th.get_data();
                } else {
                  th.toastr.error(res.message)
                }

              }
            });



            // th.router.navigate([url]);
          })

        }
      });
    }, 500);

  }
  get_data_old() {
    // this.toastr.success("Hello, I'm the toastr message.")
    var th = this;
    var datas = [];
    this.GeneralService.getMemberList().subscribe((data: any) => {
      if (data.status) {
        setTimeout(function () {

          console.log(data.member_data);

          $.each(data.member_data, function (index, value) {
            var status = (value.tm_status) ? '<a href="javascript:;" data-id="' + value.tm_id + '" data-status="0" class="badge bg-success change_status">Active</a>' : '<a href="javascript:;" data-id="' + value.tm_id + '" data-status="1" class="badge bg-danger change_status">Deactive</a>';
            datas.push([
              value.tm_id,
              value.tm_company_name,
              status,
              '<button  data-url="/member-edit/' + value.tm_id + '" class="edit_btn btn btn-primary btn-icon mr-2"><i class="fa fa-edit"></i></button>&nbsp;' +
              '<button  data-id=' + value.tm_id + ' class="verify_btn btn btn-success btn-icon mr-2"><i class="fa fa-check "></i></button>&nbsp;' +
              '<button type="button" data-id=' + value.tm_id + ' class="delete_btn btn btn-danger btn-icon"><i class="fa fa-trash"></i></button>'
            ]);
          });

          $('#DataTable').DataTable({
            destroy: true,
            data: datas,
            cache: false,
            order: [[0, 'DESC']],
            columnDefs: [{ "targets": 0, "visible": false }],
            stateSave: true,
            fnDrawCallback: function () {
              $('.edit_btn').click(function () {

                var url = $(this).data('url');

                th.router.navigate([url]);
              })
              $('.delete_btn').click(function () {
                var id = $(this).data('id');
                th.remove(id);
              })
              $('.verify_btn').click(function () {
                var id = $(this).data('id');
                th.send_data_to_android(id);
              })
              $('.change_status').click(function () {
                th.DefaultComponent.loaderShow()
                var id = $(this).data('id');
                var status = $(this).data('status');
                $.ajax({
                  url: environment.baseUrl + 'changeMemberStatus',
                  method: 'post',
                  data: { id: id, status: status },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)

                      th.get_data();
                    } else {
                      th.toastr.error(res.message)
                    }

                  }
                });
              });
            }
          });
        }, 500);
      }
    });
  }
  send_data_to_android(id) {
    console.log('{"action":"verify_member","id":' + id + '}')
    sendDataToAndroid('{"action":"verify_member","id":' + id + '}');
  }
  remove(id) {
    var th = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'to Delete this record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        th.DefaultComponent.loaderShow()
        $.ajax({
          url: environment.baseUrl + 'deleteMember',
          method: 'POST',
          data: { id: id },
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide()
            if (res.status) {
              th.toastr.success(res.message)
              th.get_data();
            } else {
              th.toastr.error(res.message)
            }
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log('cancel')
      }
    })

  }
}
