import { Injectable } from '@angular/core';


interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  { name: 'jquery', src: 'assets/libs/jquery/jquery.min.js' },
  { name: 'bootstrap', src: 'assets/libs/bootstrap/js/bootstrap.bundle.min.js' },
  { name: 'metisMenu', src: 'assets/libs/metismenu/metisMenu.min.js' },
  { name: 'simplebar', src: 'assets/libs/simplebar/simplebar.min.js' },
  { name: 'waves', src: 'assets/libs/node-waves/waves.min.js' },
  { name: 'app', src: 'assets/js/app.js' },
  { name: 'dashboard', src: 'assets/js/pages/dashboard.init.js' },
  { name: 'form', src: 'assets/js/pages/form-element.init.js' },

];
@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: any = {};
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      // if (this.scripts[name].loaded) {
      //   resolve({ script: name, loaded: true, status: 'Already Loaded' });
      // } else {
      // load script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.scripts[name].src;
      script.onload = () => {
        this.scripts[name].loaded = true;
        // console.log(`${name} Loaded.`);
        resolve({ script: name, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
      // }
    });
  }
}
