import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ScriptService } from '../../script.service';
import { GeneralService } from '../../service/general.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  total_company: any = 0;
  total_updated_company: any = 0;
  today_updated_company: any = 0;
  total_ads: any = 0;
  total_catalogue: any = 0;
  constructor(
    private scriptService: ScriptService,
    private GeneralService: GeneralService,
  ) {
    this.get_data();
    // this.scriptService.load('jquery', 'bootstrap', 'metisMenu', 'simplebar', 'waves');
  }
  get_data() {
    this.GeneralService.getDashboardData().subscribe((data: any) => {
      this.total_company = data.final_total_company
      this.today_updated_company = data.final_today_updated_company
      this.total_updated_company = data.final_total_updated_company
      this.total_ads = data.final_total_ads
      this.total_catalogue = data.final_total_catalogue

    });
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('app', 'dashboard');
  }
}
