import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {
  public categoryForm: FormGroup;
  public isSubmit = false;
  categoryIcon: any = '';
  category_id: any = 0;
  constructor(
    private route: ActivatedRoute,
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private fb: FormBuilder,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.route.params.subscribe(params => this.category_id = params.id);

    if (this.category_id) {
      var param = { id: this.category_id };
      this.GeneralService.getCategoryDetail(param).subscribe((data: any) => {
        if (data['status']) {
          if (data.category_data.tc_icon) {
            this.categoryIcon = environment.baseUrl + "category/" + data.category_data.tc_icon;
          }

          this.categoryForm.patchValue({
            name: data.category_data.tc_name,
            status: data.category_data.tc_status,
            id: data.category_data.tc_id,
            metaTitle: data.category_data.tc_meta_title,
            metaDescription: data.category_data.tc_meta_desc,
            metaKeyword: data.category_data.tc_meta_keyword,
          });
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    }
    this.categoryForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      status: [false],
      id: ['0'],
      fileSource: [''],
      metaTitle: [''],
      metaDescription: [''],
      metaKeyword: [''],
    });



  }
  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {

      if (formDetail.fileSource || this.categoryIcon) {
        this.DefaultComponent.loaderShow();
        var formData = new FormData();
        formData.append('icon', formDetail.fileSource);
        formData.append('name', formDetail.name);
        formData.append('id', formDetail.id);
        formData.append('status', formDetail.status);
        formData.append('metaTitle', formDetail.metaTitle);
        formData.append('metaDescription', formDetail.metaDescription);
        formData.append('metaKeyword', formDetail.metaKeyword);
        formData.append('login_id', this.DefaultComponent.login_id);
        this.GeneralService.saveCategory(formData).subscribe((res: any) => {
          this.DefaultComponent.loaderHide()
          var data = res;
          if (data.status) {
            if (formDetail.id > 0) {
              this.toastr.success(data.message)
              this.router.navigate(['/category-list']);
            } else {
              this.toastr.success(data.message)
              location.reload();
            }
          } else {
            this.toastr.error(data.message)
          }
        }, (err: any) => {
          console.error('Error:-', err);
        });
      } else {
        this.categoryForm.controls['fileSource'].setErrors({ required: true });
      }

    }
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#customFile").val('');
        //this.groupPhotoURL = '';
        this.toastr.error("Only images are supported.");
        return;
      }
      this.categoryForm.patchValue({
        fileSource: file
      });
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.categoryIcon = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');
  }
}
