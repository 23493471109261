<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <p class="card-title-desc"></p>
                <form [formGroup]="memberExcelDetailsForm"
                    (ngSubmit)="isSubmit= true; submitForm($event, memberExcelDetailsForm.valid, memberExcelDetailsForm.value)"
                    enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Company Name</label>
                                <input class="form-control" type="text" formControlName="name" placeholder="Name">
                                <span *ngIf="memberExcelDetailsForm.get('name')?.errors && isSubmit">
                                    <span *ngIf="memberExcelDetailsForm.get('name')?.errors?.required && isSubmit"
                                        class="text-danger">
                                        Please enter company name
                                    </span>
                                    <span
                                        *ngIf="memberExcelDetailsForm.get('name')?.errors?.maxlength || memberExcelDetailsForm.get('name')?.errors?.minlength "
                                        class="text-danger">
                                        Company name should be of minimum 3 characters OR a maximum of 30
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label class="form-label">Categories</label>
                                    <select id="categories" class="select2 form-control select2-multiple"
                                        multiple="multiple" data-placeholder="Choose ...">
                                        <option *ngFor="let cl of categoryList" value="{{cl.tc_id}}">
                                            {{cl.tc_name}}
                                        </option>
                                    </select>
                                    <span *ngIf="memberExcelDetailsForm.get('categories')?.errors && isSubmit">
                                        <span
                                            *ngIf="memberExcelDetailsForm.get('categories')?.errors?.required && isSubmit"
                                            class="text-danger">
                                            Please select category
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Work Address</label>
                                <textarea class="form-control" type="text" formControlName="work_address"
                                    placeholder="Work Address"></textarea>
                                <span *ngIf="memberExcelDetailsForm.get('work_address')?.errors && isSubmit">
                                    <span
                                        *ngIf="memberExcelDetailsForm.get('work_address')?.errors?.required && isSubmit"
                                        class="text-danger">
                                        Please enter work address
                                    </span>
                                    <span *ngIf="memberExcelDetailsForm.get('work_address')?.errors?.minlength"
                                        class="text-danger">
                                        Work address is too short
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Office Address</label>
                                <textarea class="form-control" type="text" formControlName="ofc_address"
                                    placeholder="Office Address"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Village</label>
                                <input class="form-control" type="text" formControlName="Village" placeholder="Village">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact Person</label>
                                <input class="form-control" type="text" formControlName="Contact_Person"
                                    placeholder="Contact Person">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact No</label>
                                <input class="form-control" type="text" formControlName="Contact_No"
                                    placeholder="Contact No">

                                <span *ngIf="memberExcelDetailsForm.get('Contact_No')?.errors && isSubmit">
                                    <span *ngIf="memberExcelDetailsForm.get('Contact_No')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid number allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact Person 2</label>
                                <input class="form-control" type="text" formControlName="Contact_Person_2"
                                    placeholder="Contact Person 2">

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact No 2</label>
                                <input class="form-control" type="text" formControlName="Contact_No_2"
                                    placeholder="Contact No 2">
                                <span *ngIf="memberExcelDetailsForm.get('Contact_No_2')?.errors && isSubmit">
                                    <span
                                        *ngIf="memberExcelDetailsForm.get('Contact_No_2')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid number allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Email ID</label>
                                <input class="form-control" type="text" formControlName="Email_ID"
                                    placeholder="Email ID">
                                <span *ngIf="memberExcelDetailsForm.get('Email_ID')?.errors && isSubmit">
                                    <span *ngIf="memberExcelDetailsForm.get('Email_ID')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid email allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Email ID 2</label>
                                <input class="form-control" type="text" formControlName="Email_ID_2"
                                    placeholder="Email ID 2">
                                <span *ngIf="memberExcelDetailsForm.get('Email_ID_2')?.errors && isSubmit">
                                    <span *ngIf="memberExcelDetailsForm.get('Email_ID_2')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid email allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Brand Name</label>
                                <input class="form-control" type="text" formControlName="Brand"
                                    placeholder="Brand Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Business Type</label>
                                <input class="form-control" type="text" formControlName="business_type"
                                    placeholder="Business Type">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact Person No</label>
                                <input class="form-control" type="text" formControlName="Contact_Person_No"
                                    placeholder="Contact Person No">
                                <span *ngIf="memberExcelDetailsForm.get('Contact_Person_No')?.errors && isSubmit">
                                    <span
                                        *ngIf="memberExcelDetailsForm.get('Contact_Person_No')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid number allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Contact Person No 2</label>
                                <input class="form-control" type="text" formControlName="Contact_Person_No_2"
                                    placeholder="Contact Person No 2">
                                <span *ngIf="memberExcelDetailsForm.get('Contact_Person_No_2')?.errors && isSubmit">
                                    <span
                                        *ngIf="memberExcelDetailsForm.get('Contact_Person_No_2')?.errors?.pattern && isSubmit"
                                        class="text-danger">
                                        Only valid number allowed
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label">Certified</label>
                                <input class="form-control" type="text" formControlName="Certified"
                                    placeholder="Certified">
                            </div>
                        </div>
                        <div class="mt-4" id="memberExcelFormHideShow">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>