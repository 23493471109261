import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemberexcelRoutingModule } from './memberexcel-routing.module';
import { FormComponent } from './form/form.component';
import { ExcelListComponent } from './excel-list/excel-list.component';


@NgModule({
  declarations: [FormComponent, ExcelListComponent],
  imports: [
    CommonModule,
    MemberexcelRoutingModule,
    ReactiveFormsModule,
    FormsModule

  ]
})
export class MemberexcelModule { }
