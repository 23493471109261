<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Manage Members</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a routerLink="/member-list">Members</a></li>
                            <li class="breadcrumb-item active">Add New</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <h4 class="card-title">Add New Member</h4>
                        <p class="card-title-desc"></p>
                        <form class="" [formGroup]="memberForm"
                            (ngSubmit)="isSubmit= true; submitForm($event, memberForm.valid, memberForm.value)">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Name</label>
                                        <input class="form-control" type="text" formControlName="name"
                                            placeholder="Name">
                                        <span *ngIf="memberForm.get('name')?.errors && isSubmit">
                                            <span *ngIf="memberForm.get('name')?.errors?.required && isSubmit"
                                                class="text-danger">
                                                Please enter member name
                                            </span>
                                            <span
                                                *ngIf="memberForm.get('name')?.errors?.maxlength || memberForm.get('name')?.errors?.minlength "
                                                class="text-danger">
                                                Member name should be of minimum 3 characters OR a maximum of 30
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Categories</label>
                                        <select id="categories" class="select2 form-control select2-multiple"
                                            multiple="multiple" data-placeholder="Choose ...">
                                            <option *ngFor="let cl of categoryList" value="{{cl.tc_id}}">{{cl.tc_name}}
                                            </option>
                                        </select>
                                        <span *ngIf="memberForm.get('categories')?.errors && isSubmit">
                                            <span *ngIf="memberForm.get('categories')?.errors?.required && isSubmit"
                                                class="text-danger">
                                                Please select category
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Office Address</label>
                                        <textarea class="form-control" type="text" formControlName="ofc_address"
                                            placeholder="Office Address"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Work Address</label>
                                        <textarea class="form-control" type="text" formControlName="work_address"
                                            placeholder="Work Address"></textarea>
                                        <span *ngIf="memberForm.get('work_address')?.errors && isSubmit">
                                            <span *ngIf="memberForm.get('work_address')?.errors?.required && isSubmit"
                                                class="text-danger">
                                                Please enter work address
                                            </span>
                                            <span *ngIf="memberForm.get('work_address')?.errors?.minlength "
                                                class="text-danger">
                                                Work address is too short
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Phone 1</label>
                                        <input class="form-control" type="text" formControlName="phone_1"
                                            placeholder="Phone 1">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Phone 2</label>
                                        <input class="form-control" type="text" formControlName="phone_2"
                                            placeholder="Phone 2">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Email 1</label>
                                        <input class="form-control" type="text" formControlName="email_1"
                                            placeholder="Email 1">
                                        <span *ngIf="memberForm.get('email_1')?.errors && isSubmit">

                                            <span *ngIf="memberForm.get('email_1')?.errors?.pattern"
                                                class="text-danger">
                                                Please enter valid email
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Email 2</label>
                                        <input class="form-control" type="text" formControlName="email_2"
                                            placeholder="Email 2">
                                        <span *ngIf="memberForm.get('email_2')?.errors && isSubmit">
                                            <span *ngIf="memberForm.get('email_2')?.errors?.pattern"
                                                class="text-danger">
                                                Please enter valid email
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Member Name 1</label>
                                        <input class="form-control" type="text" formControlName="member_name_1"
                                            placeholder="Member Name 1">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Member Mobile
                                            1 <code>(Whatsapp Number)</code></label>
                                        <input class="form-control" type="text" formControlName="member_mobile_1"
                                            placeholder="Member Mobile 1">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Member Name 2</label>
                                        <input class="form-control" type="text" formControlName="member_name_2"
                                            placeholder="Member Name 2">
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Member Mobile
                                            2</label>
                                        <input class="form-control" type="text" formControlName="member_mobile_2"
                                            placeholder="Member Mobile 2">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Website
                                            <code>(without http:// or https://)</code></label>
                                        <input class="form-control" type="text" formControlName="website"
                                            placeholder="Website">
                                        <span *ngIf="memberForm.get('website')?.errors && isSubmit">
                                            <span *ngIf="memberForm.get('website')?.errors?.pattern"
                                                class="text-danger">
                                                Please enter valid website URL
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Business Type</label>
                                        <textarea class="form-control" type="text" formControlName="business_type"
                                            placeholder="Business Type"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Brand Name</label>
                                        <textarea class="form-control" type="text" formControlName="brand_name"
                                            placeholder="Brand Name"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mb-3">
                                        <label class="form-label">Certification</label>
                                        <textarea class="form-control" type="text" formControlName="certification"
                                            placeholder="Certification"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Company
                                            Brochure</label>
                                        <input type="file" class="form-control" id="brochure"
                                            (change)="onFileChangeBrochure($event)">
                                        <span *ngIf="memberBrochure">
                                            <a href="{{memberBrochure}}" target='_blank'>View</a>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-lastname-input">Logo</label>
                                        <input type="file" class="form-control" id="logo"
                                            (change)="onFileChange($event)">

                                        <img [src]="memberLogo" *ngIf="memberLogo" alt="member-icon"
                                            class="rounded avatar-sm mt-2">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-lastname-input">Original Logo</label>
                                        <input type="file" class="form-control" id="original_logo"
                                            (change)="onFileChange2($event)">

                                        <img [src]="memberOriginalLogo" *ngIf="memberOriginalLogo" alt="member-icon"
                                            class="rounded avatar-sm mt-2">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-lastname-input">Banner</label>
                                        <input type="file" class="form-control" id="banner"
                                            (change)="onFileChangeBanner($event)">

                                        <img [src]="memberBanner" *ngIf="memberBanner" alt="member-icon"
                                            class="rounded avatar-sm mt-2">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">About Company
                                        </label>
                                        <!-- <textarea class="form-control" type="text" formControlName="about_company"
                                            placeholder="About Company For Catalogue"></textarea> -->
                                        <ckeditor [editor]="Editor" formControlName="about_company"></ckeditor>

                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Facebook Link</label>
                                        <input class="form-control" type="text" formControlName="facebook_link"
                                            placeholder="Facebook Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Twitter Link</label>
                                        <input class="form-control" type="text" formControlName="twitter_link"
                                            placeholder="Twitter Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Youtube Link</label>
                                        <input class="form-control" type="text" formControlName="youtube_link"
                                            placeholder="Youtube Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Instagram Link</label>
                                        <input class="form-control" type="text" formControlName="instagram_link"
                                            placeholder="Instagram Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Pinterest Link</label>
                                        <input class="form-control" type="text" formControlName="pinterest_link"
                                            placeholder="Pinterest Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Linkedin Link</label>
                                        <input class="form-control" type="text" formControlName="linkedin_link"
                                            placeholder="Linkedin Link">
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="mb-3">
                                        <label class="form-label">Map Iframe</label>
                                        <input class="form-control" type="text" formControlName="map_iframe"
                                            placeholder="Map Iframe">


                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label">Verified Badge?</label>
                                        <div class="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" formControlName="verified" class="form-check-input"
                                                id="customSwitch1">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label">Is Member?</label>
                                        <div class="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" formControlName="is_member" class="form-check-input"
                                                id="customSwitch1">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label">Lifetime Badge?
                                        </label>
                                        <div class="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" formControlName="lifetime_member"
                                                class="form-check-input" id="customSwitch1">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label">Catalouge Purchased?</label>
                                        <div class="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" formControlName="paid_member"
                                                class="form-check-input" id="customSwitch1">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="mb-3">
                                        <label class="form-label" for="basicpill-lastname-input">Status</label>
                                        <div class="form-check form-switch mb-3" dir="ltr">
                                            <input type="checkbox" formControlName="status" class="form-check-input"
                                                id="customSwitch1">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label">Products</label>
                                    <div class="row mb-3" *ngFor="let dv of product_list; let i = index;">
                                        <div class="col-lg-5">
                                            <input class="form-control" name="title"
                                                (input)="update_product_title($event,i)" value="{{dv.title}}"
                                                type="text" placeholder="Title">
                                        </div>
                                        <div class="col-lg-5">
                                            <input type="file" (change)="onFileChangeProduct($event,i)" id="image{{i}}"
                                                class="form-control">
                                            <img [src]="dv.thumb" *ngIf="dv.thumb" alt="member-icon"
                                                class="rounded avatar-sm mt-2">
                                        </div>
                                        <div class="col-lg-2">
                                            <button type="button" *ngIf="(i==0)" class="btn btn-success"
                                                (click)="addProductValue()">+</button>

                                            <button type="button" *ngIf="(i>0)" class="btn btn-danger"
                                                (click)="removeProductValue(i)">-</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label">Search</label>
                                    <div class="row mb-3" *ngFor="let dv of search_tag_list; let i = index;">
                                        <div class="col-lg-10">
                                            <input (input)="update_search_tag($event,i)" class="form-control"
                                                value="{{dv.value}}" name="search_tag" type="text" placeholder="Tag">
                                        </div>

                                        <div class="col-lg-2">
                                            <button type="button" *ngIf="(i==0)" class="btn btn-success"
                                                (click)="addSearchTagValue()">+</button>

                                            <button type="button" *ngIf="(i>0)" class="btn btn-danger"
                                                (click)="removeSearchTagValue(i)">-</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div> <!-- end col -->
        </div>
        <!-- end row -->


        <!-- end row -->
    </div> <!-- container-fluid -->
</div>