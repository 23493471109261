<div class="page-content">
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0">Manage Advertise</h4>

                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item"><a routerLink="/advertise-list">Advertise</a></li>
                            <li class="breadcrumb-item active">Add New</li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <h4 class="card-title">Add Advertise</h4>
                        <p class="card-title-desc"></p>
                        <form class="" [formGroup]="advertiseForm"
                            (ngSubmit)="isSubmit= true; submitForm($event, advertiseForm.valid, advertiseForm.value)">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Members</label>
                                        <select id="member" class="select2 form-control">
                                            <option value="">Select</option>
                                            <option *ngFor="let cl of memberList" value="{{cl.tm_id}}">
                                                {{cl.tm_company_name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Category</label>
                                        <select id="categories" class="select2 form-control">
                                            <option value="">Select</option>
                                            <option value="0">Home</option>
                                            <option *ngFor="let cl of categoryList" value="{{cl.tc_id}}">
                                                {{cl.tc_name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Ads Type</label>
                                        <select id="ads_type" class="select2 form-control">
                                            <option value="">Select</option>
                                            <optgroup label="Home Page">
                                                <option value="home_master_prime_fix">Master Prime Fix</option>
                                                <option value="home_fix_prime_1">Fix Prime 1</option>
                                                <option value="home_fix_prime_2">Fix Prime 2</option>
                                                <option value="home_fix_prime_3">Fix Prime 3</option>
                                                <option value="home_fix_prime_4">Fix Prime 4</option>
                                                <option value="home_fix_prime_5">Fix Prime 5</option>
                                                <option value="home_fix_prime_6">Fix Prime 6</option>
                                                <option value="home_fix_prime_7">Fix Prime 7</option>
                                                <option value="home_fix_prime_8">Fix Prime 8</option>
                                                <option value="home_box_slider_banner">Box Slider Banner</option>
                                                <option value="home_top_banner">Top Banner</option>
                                                <option value="home_strip_banner">Strip Banner</option>
                                                <option value="home_business_card">Business Card</option>
                                                <option value="home_master_prime_fix_mobile">Master Prime Fix Mobile
                                                </option>
                                            </optgroup>
                                            <optgroup label="Category Page">
                                                <option value="cat_prime_fix">Prime Fix Category</option>
                                                <option value="cat_top_banner">Top Banner</option>
                                                <option value="cat_slider">Slider</option>
                                                <option value="cat_business_card">Business Card</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="form-label">Advertise</label>
                                    <div class="row mb-3" *ngFor="let dv of ads_list; let i = index;">

                                        <div class="col-lg-5">
                                            <input type="file" (change)="onFileChange($event,i)" id="image{{i}}"
                                                class="form-control">
                                            <img [src]="dv.thumb" *ngIf="dv.thumb" alt="member-icon"
                                                class="rounded avatar-sm mt-2">
                                        </div>
                                        <div class="col-lg-5">
                                            <input class="form-control" name="Website"
                                                (input)="update_ads_website($event,i)" value="{{dv.website}}"
                                                type="text" placeholder="Website">
                                        </div>
                                        <div class="col-lg-2">
                                            <button type="button" *ngIf="(i==0)" class="btn btn-success"
                                                (click)="addAdsValue()">+</button>

                                            <button type="button" *ngIf="(i>0)" class="btn btn-danger"
                                                (click)="removeAdsValue(i)">-</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#home" role="tab">
                                        <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                        <span class="d-none d-sm-block">Home</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#profile" role="tab">
                                        <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                        <span class="d-none d-sm-block">Profile</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#messages" role="tab">
                                        <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                        <span class="d-none d-sm-block">Messages</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#settings" role="tab">
                                        <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                                        <span class="d-none d-sm-block">Settings</span>
                                    </a>
                                </li>
                            </ul> -->
                            <div class="text-center mt-4">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div> <!-- end col -->
        </div>
        <!-- end row -->


        <!-- end row -->
    </div> <!-- container-fluid -->
</div>