import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit, AfterViewInit {
  public advertiseForm: FormGroup;
  public isSubmit = false;
  categoryIcon: any = '';
  advertise_id: any = 0;
  advertise_member_id: any = 0;
  memberList: any = [];
  categoryList: any = [];
  memberAdsList: any = [];
  ads_list: any[] = [{
    website: '',
    image: '',
    thumb: ''
  }];
  constructor(
    private route: ActivatedRoute,
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private fb: FormBuilder,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {

    this.route.params.subscribe(params => this.advertise_id = params.id);
    this.GeneralService.getMemberListActive().subscribe((data: any) => {
      if (data['status']) {
        this.memberList = data.member_data;
        this.setMemberSelect2();
      }
    });
    this.GeneralService.getCategoryListActive().subscribe((data: any) => {
      if (data['status']) {
        this.categoryList = data.category_data;
        this.setCategorySelect2();
      }
    });
    this.advertiseForm = this.fb.group({
      id: ['0']
    });
  }
  setMemberSelect2() {
    var th = this;
    setTimeout(() => {
      if (th.advertise_member_id > 0) {
        $('#member').val(th.advertise_member_id).trigger('change');
      } else {
        $("#member").select2();
      }
    }, 500);
  }
  setCategorySelect2() {
    setTimeout(() => {
      $("#categories").select2();
    }, 500);
  }
  setMemberSelect2Edit(id) {
    setTimeout(() => {


    }, 1000);
  }
  setCategorySelect2Edit(id) {
    setTimeout(() => {
      $('#categories').val(id).trigger('change');
    }, 500);
  }
  setAdsSelect2Edit() {
    setTimeout(() => {
      $('#ads_type').trigger('change');
    }, 500);
  }
  update_ads_website(e, index) {
    this.ads_list[index]['website'] = e.target.value;
  }
  addAdsValue() {
    this.ads_list.push({
      website: '',
      image: '',
      thumb: '',
    });
  }
  removeAdsValue(i: number) {
    this.ads_list.splice(i, 1);
  }
  onFileChange(event, index) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        $("#image" + index).val('');
        this.toastr.error("Only images are supported for product");
        return;
      }
      this.ads_list[index]['image'] = file;
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.ads_list[index]['thumb'] = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  submitForm(event: Event, isValid: Boolean, formDetail: any) {
    if (isValid) {
      var member = $("#member").val();
      var categories = $("#categories").val();
      var ads_type = $("#ads_type").val();
      if (member != '') {
        if (categories != '') {
          if (ads_type != '') {
            this.DefaultComponent.loaderShow();
            var formData = new FormData();
            formData.append('categories', categories);
            formData.append('member', member);
            formData.append('ads_type', ads_type);

            formData.append('id', formDetail.id);

            for (var i = 0; i < this.ads_list.length; i++) {
              if (this.ads_list[i].image) {
                formData.append('ads_image' + i, this.ads_list[i].image);
                this.ads_list[i]['thumb'] = '';
              }
            }
            formData.append('ads', JSON.stringify(this.ads_list));
            this.GeneralService.saveAds(formData).subscribe((res: any) => {
              this.DefaultComponent.loaderHide()
              var data = res;
              if (data.status) {
                if (formDetail.id > 0) {
                  this.toastr.success(data.message)
                  this.router.navigate(['/advertise-list']);
                } else {
                  this.toastr.success(data.message)
                  location.reload();
                }
              } else {
                this.toastr.error(data.message)
              }
            }, (err: any) => {
              console.error('Error:-', err);
            });
          } else {
            this.toastr.error("Please select ads type")
          }
        } else {
          this.toastr.error("Please select category")
        }
      } else {
        this.toastr.error("Please select member")
      }

    }
  }
  get_member_ads(id) {
    var param = { member_id: id }
    this.GeneralService.getMemberAdvertiseList(param).subscribe((res: any) => {
      if (res['status']) {
        this.memberAdsList = res.data;
      }
    });
  }

  ngOnInit(): void {
  }
  setAdsDetail() {
    if (this.advertise_id) {
      var param = { id: this.advertise_id };
      this.GeneralService.getAdsDetail(param).subscribe((data: any) => {
        if (data['status']) {

          this.advertiseForm.patchValue({
            id: data.ads_data.tam_id,
          });
          this.ads_list = [];
          for (var i = 0; i < data.ads_img_data.length; i++) {
            this.ads_list.push({
              website: data.ads_img_data[i]['tad_website'],
              image: '',
              id: data.ads_img_data[i]['tad_id'],
              thumb: environment.baseUrl + "ads/" + data.ads_img_data[i]['tad_image'],
            });
          }


          this.setCategorySelect2Edit(data.ads_data.tam_category_id);

          this.advertise_member_id = data.ads_data.tam_member_id;
          // this.setMemberSelect2Edit(data.ads_data.tam_member_id);

          $('#ads_type').val(data.ads_data.tam_type);
          this.setAdsSelect2Edit();
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
    }
  }
  ngAfterViewInit() {
    this.scriptService.load('form', 'app', 'dashboard');


    $("#ads_type").select2();
    var th = this;
    this.setAdsDetail();
    // $("#member").change(function () {
    //   var member_id = $(this).val();
    //   th.get_member_ads(member_id)
    // });
  }
}
