import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService, SPINNER } from "ngx-ui-loader";
declare var $: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  SPINNER: SPINNER;
  spinner_type = SPINNER.threeStrings;
  fgsColor = '#f58532';
  login_id: any = '0';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
  ) {
    if (!localStorage.getItem('svia_admin_data')) {
      this.router.navigate(['/signin']);
    }
    this.login_id = localStorage.getItem('svia_login_id');
  }
  loaderShow() {
    this.ngxService.start();
  }
  loaderHide() {
    this.ngxService.stop();
  }
  ngOnInit(): void {
    $("body").removeClass('auth-body-bg');
  }
  logout() {
    localStorage.removeItem('svia_login_id');
    localStorage.removeItem('svia_login_name');
    localStorage.removeItem('svia_admin_data');
    this.router.navigate(['/signin']);
  }
}
