import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultComponent } from './layouts/default/default.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CategoryModule } from './category/category.module';
import { MemberModule } from './member/member.module';
import { AdvertiseModule } from './advertise/advertise.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ToastrModule } from 'ngx-toastr';
import { MemberexcelModule } from './memberexcel/memberexcel.module';
import { LinkModule } from './link/link.module';



@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    FullComponent
  ],
  imports: [
    BrowserModule,
    AuthModule,
    DashboardModule,
    CategoryModule,
    AdvertiseModule,
    MemberModule,
    MemberexcelModule,
    LinkModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      tapToDismiss: true,
      progressAnimation: 'increasing',
      maxOpened: 1,
    }),
    NgxUiLoaderModule,
    AppRoutingModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
