import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  serverUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public signIn(payload) {

    return this.http.post(this.serverUrl + 'adminSignIn', payload);
  }

}
