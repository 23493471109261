import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ScriptService } from '../../script.service';
import { DefaultComponent } from '../../layouts/default/default.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../../service/general.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { link } from 'fs';
declare var $: any;

@Component({
  selector: 'app-excel-list',
  templateUrl: './excel-list.component.html',
  styleUrls: ['./excel-list.component.css']
})
export class ExcelListComponent implements OnInit {
  memberDetail: any = []
  categoryList: any = [];
  constructor(
    private DefaultComponent: DefaultComponent,
    private scriptService: ScriptService,
    private GeneralService: GeneralService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.GeneralService.getCategoryListActive().subscribe((data: any) => {
      if (data['status']) {
        this.categoryList = data.category_data;
      }
    });
    this.get_data();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.scriptService.load('app', 'dashboard');
  }

  convertInt(input) {
    // Replace all spaces with an empty string
    var processed = input.replace(/ /g, '');
    var output = parseInt(processed, 10);
    return output;
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  updateStatus(event) {
    console.log('test');
    return false;
  }

  shareOnWhatsApp(id) {

    var memDetail = this.memberDetail.find((item: any) => item.tmd_id === id)

    var link = '' + location.origin + '/%23/links/' + this.utf8_to_b64(id) + '';
    // href="https://api.whatsapp.com/send?phone=+91' + th.convertInt(value.contact_no) + '&text=' + link + '"

    var details = '';
    if (memDetail.name && memDetail.name != null && memDetail.name != undefined && memDetail.name != 'null') {
      details += 'Name : ' + memDetail.name + '\n';
    } else {
      //details += 'Name : \n';
    }
    if (memDetail.address && memDetail.address != null && memDetail.address != undefined && memDetail.address != 'null') {
      details += 'Address : ' + memDetail.address + '\n';
    } else {
      //details += 'Address : \n';
    }
    if (memDetail.village && memDetail.village != null && memDetail.village != undefined && memDetail.village != 'null') {
      details += 'Village : ' + memDetail.village + '\n';
    } else {
      //details += 'Village : \n';
    }
    if (memDetail.contact_person && memDetail.contact_person != null && memDetail.contact_person != undefined && memDetail.contact_person != 'null') {
      details += 'Contact Person : ' + memDetail.contact_person + '\n';
    } else {
      //details += 'Contact Person : \n';
    }
    if (memDetail.contact_no && memDetail.contact_no != null && memDetail.contact_no != undefined && memDetail.contact_no != 'null') {
      details += 'Contact No. : ' + memDetail.contact_no + '\n';
    } else {
      //details += 'Contact No. : \n';
    }
    if (memDetail.contact_person_2 && memDetail.contact_person_2 != null && memDetail.contact_person_2 != undefined && memDetail.contact_person_2 != 'null') {
      details += 'Contact Person 2 : ' + memDetail.contact_person_2 + '\n';
    } else {
      //details += 'Contact Person 2 : \n';
    }
    if (memDetail.contact_no_2 && memDetail.contact_no_2 != null && memDetail.contact_no_2 != undefined && memDetail.contact_no_2 != 'null') {
      details += 'Contact No. 2 : ' + memDetail.contact_no_2 + '\n';
    } else {
      //details += 'Contact No. 2 : \n';
    }
    if (memDetail.email_id && memDetail.email_id != null && memDetail.email_id != undefined && memDetail.email_id != 'null') {
      details += 'Email : ' + memDetail.email_id + '\n';
    } else {
      //details += 'Email : \n';
    }
    if (memDetail.email_id_2 && memDetail.email_id_2 != null && memDetail.email_id_2 != undefined && memDetail.email_id_2 != 'null') {
      details += 'Email 2 : ' + memDetail.email_id_2 + '\n';
    } else {
      //details += 'Email 2 : \n';
    }
    if (memDetail.text && memDetail.text != null && memDetail.text != undefined && memDetail.text != 'null') {
      details += 'Brand : ' + memDetail.text + '\n';
    } else {
      //details += 'Brand : \n';
    }
    if (memDetail.type && memDetail.type != null && memDetail.type != undefined && memDetail.type != 'null') {
      details += 'Bussiness Type : ' + memDetail.type + '\n';
    } else {
      //details += 'Bussiness Type : \n';
    }
    if (memDetail.office_address && memDetail.office_address != null && memDetail.office_address != undefined && memDetail.office_address != 'null') {
      details += 'Office Address : ' + memDetail.office_address + '\n';
    } else {
      //details += 'Office Address : \n';
    }
    if (memDetail.certified && memDetail.certified != null && memDetail.certified != undefined && memDetail.certified != 'null') {
      details += 'Certified : ' + memDetail.certified + '\n';
    } else {
      //details += 'Certified : \n';
    }
    if (memDetail.website && memDetail.website != null && memDetail.website != undefined && memDetail.website != 'null') {
      details += 'Website : ' + memDetail.website + '\n';
    } else {
      //details += 'Certified : \n';
    }
    if (memDetail.contact_person_no && memDetail.contact_person_no != null && memDetail.contact_person_no != undefined && memDetail.contact_person_no != 'null') {
      details += 'Phone 1 : ' + memDetail.contact_person_no + '\n';
    } else {
      //details += 'Phone 1 : \n';
    }
    if (memDetail.contact_person_no_2 && memDetail.contact_person_no_2 != null && memDetail.contact_person_no_2 != undefined && memDetail.contact_person_no_2 != 'null') {
      details += 'Phone 2 : ' + memDetail.contact_person_no_2 + '\n';
    } else {
      //details += 'Phone 2 : \n';
    }
    var categoryDetail = '';
    if (memDetail.category && memDetail.category.length > 0) {
      for (var j = 0; j < memDetail.category.length; j++) {
        var filter_array = this.categoryList.find(obj => obj.tc_id === memDetail.category[j]);
        if (filter_array != undefined) {
          if (j == 0) {
            categoryDetail += filter_array.tc_name
          } else {
            categoryDetail += ', ' + filter_array.tc_name
          }
        }
      }
      details += 'Category : ' + categoryDetail + ' \n';
    } else {
      details += 'Category : \n';
    }

    var message = encodeURIComponent(details);
    var whatsapp_url = 'https://api.whatsapp.com/send?phone=+91' + this.convertInt(memDetail.contact_no) + '&text=' + message;
    //window.location.href = whatsapp_url;
    window.open(
      whatsapp_url,
      '_blank'
    );
  }

  get_data() {
    // this.toastr.success("Hello, I'm the toastr message.")
    var th = this;
    var datas = [];

    this.GeneralService.getMemberExcelList().subscribe((data: any) => {

      if (data.status) {
        this.memberDetail = data.memberExce_data;
        setTimeout(function () {
          $.each(data.memberExce_data, function (index, value) {
            var status = (value.update_status) ? '<a href="javascript:;" data-id="' + value.tmd_id + '" data-status="0" class="badge bg-success change_status">Active</a>' : '<a href="javascript:;" data-id="' + value.tmd_id + '" data-status="1" class="badge bg-danger change_status">Deactive</a>';
            // if (value.update_status == 1) {
            //   var status = '<input type="checkbox" [value]="' + value.tmd_id + '" ng-click="updateStatus($event)" checked>';
            // } else {
            //   var status = '<input type="checkbox" [value]="' + value.tmd_id + '" ng-click="updateStatus($event)">';
            // }
            var dataSend2 = '';
            if (value.is_data_send) {
              var dataSend = '<input type="checkbox" data-id="' + value.tmd_id + '" class="data_send" disabled checked>';
            } else {
              var dataSend = '<input type="checkbox" data-id="' + value.tmd_id + '" class="data_send" disabled>';
            }
            // if (value.update_status) {
            //   var isDisabled = 'disabled';
            // } else {
            //   var isDisabled = '';
            // }
            if (value.is_data_send_2) {
              var dataSend2 = '<input type="checkbox" data-id="' + value.tmd_id + '" class="data_send_2"  checked>';
            } else {
              var dataSend2 = '<input type="checkbox" data-id="' + value.tmd_id + '" class="data_send_2" >';
            }
            if (value.update_status) {
              var dataSend2 = '';
              var buttons = '<button disabled class="btn btn-primary btn-icon mr-2"><i class="fa fa-edit"></i></button>';
            } else {
              var buttons = '<button  data-url="/member-excel-add/' + th.utf8_to_b64(value.tmd_id) + '" class="edit_btn btn btn-primary btn-icon mr-2"><i class="fa fa-edit"></i></button>';
            }
            datas.push([
              value.tmd_id,
              value.name + '<br><a data-id="' + value.tmd_id + '" href="javascript:void(0)"  class="share_btn btn btn-info btn-icon mr-2"><i class="fa fa-phone"></i></a>&nbsp;' + dataSend + '&nbsp;&nbsp;' + dataSend2,
              value.contact_person,
              th.convertInt(value.contact_no),
              value.date,
              status,
              buttons
            ])
          });

          $('#DataTable').DataTable({
            destroy: true,
            data: datas,
            cache: false,
            order: [[0, 'asc']],
            columnDefs: [{ "visible": false }],
            stateSave: true,
            scrollX: true,
            fnDrawCallback: function () {
              $('.data_send').change(function () {
                var id = $(this).data('id');
                var status = $(this).prop('checked');
                th.DefaultComponent.loaderShow()
                $.ajax({
                  url: environment.baseUrl + 'changeMemberExcelDataSend',
                  method: 'post',
                  data: { id: id, status: status },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)


                    } else {
                      th.toastr.error(res.message)
                    }

                  }
                });
              })
              $('.data_send_2').change(function () {
                var id = $(this).data('id');
                var status = $(this).prop('checked');
                th.DefaultComponent.loaderShow()
                $.ajax({
                  url: environment.baseUrl + 'changeMemberExcelDataSend2',
                  method: 'post',
                  data: { id: id, status: status },
                  dataType: 'json',
                  success: function (res) {
                    th.DefaultComponent.loaderHide()
                    if (res.status) {
                      th.toastr.success(res.message)


                    } else {
                      th.toastr.error(res.message)
                    }

                  }
                });
              })
              $('.share_btn').click(function () {
                var id = $(this).data('id');
                th.shareOnWhatsApp(id)
              })
              $('.edit_btn').click(function () {
                var url = $(this).data('url');
                th.router.navigate([url]);
              }),
                $('.change_status').click(function () {
                  var id = $(this).data('id');
                  var status = $(this).data('status');
                  th.DefaultComponent.loaderShow()
                  $.ajax({
                    url: environment.baseUrl + 'changeMemberExcelStatus',
                    method: 'post',
                    data: { id: id, status: status },
                    dataType: 'json',
                    success: function (res) {
                      th.DefaultComponent.loaderHide()
                      if (res.status) {
                        th.toastr.success(res.message)

                        th.get_data();
                      } else {
                        th.toastr.error(res.message)
                      }

                    }
                  });
                });
            }
          });

        }, 500);
      }
    });
  }
}